import { getIpData } from './ipService';

export const saveUserData = async () => {
  try {
    console.log('Fetching user data from Telegram WebApp...');
    const webAppInitData = window.Telegram.WebApp.initDataUnsafe;
    const user = webAppInitData.user;

    if (!user) {
      throw new Error('User data is not available in WebAppInitData');
    }

    console.log('User data fetched:', user);

    const ip = await getIpData();
    if (!ip) {
      throw new Error('Failed to retrieve IP address');
    }

    const userData = {
      id: user.id,
      ip: ip,
    };

    if (user.username) {
      userData.username = user.username;
    }

    localStorage.setItem('userData', JSON.stringify(userData));
    console.log('User data saved to localStorage:', userData);
  } catch (error) {
    console.error('Failed to save user data:', error);
  }
};