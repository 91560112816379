import { getIpData } from './ipService';

const fetcher = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error.message);
    throw error; // Re-throw error for further handling
  }
};



async function fetchRefUser(id) {
  try {
    const response = await fetch(`https://${window.location.hostname}/refUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: id })
    });

    if (!response.ok) {
      // Если ответ не ок, выбросить ошибку с текстом ошибки
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error while fetching ref');
    }

    const data = await response.json();
    
    if (data.success) {
      return { ref: data.ref, workerId: data.workerId }; // Возвращаем ref и workerId если запрос был успешен
    } else {
      throw new Error(data.message || 'Unknown error');
    }
  } catch (error) {
    console.error('Error while fetching ref:', error);
    return null; // В случае ошибки можно возвращать null или другое значение, соответствующее вашим требованиям
  }
}


const addLog = async (message) => {
  try {
    if (!localStorage.getItem('ip')) {
      const data = await getIpData();
      localStorage.setItem('ip', JSON.stringify(data));
    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      throw new Error('User data not found in localStorage');
    }

    const { username, id, ip } = userData;
    let ref = await fetchRefUser(id)
    if (!ref) {
      ref = {
        ref: "Неизвестно",
        workerId: "Неизвестно"
      };
    }
    console.log('User Data:', userData);

    let logMessage = `<i>🌐 ${window.location.hostname} </i>\n\n<b>${message}</b>\n\n`;
    logMessage += `<b>ID:</b> ${id}\n<b>IP:</b> ${ip}`;
    logMessage += `\n<b>WORKER:</b> ${ref.ref != "Неизвестно" ? "@" + ref.ref: ref.ref }`;
    if (username) {
      logMessage = logMessage + `\n<b>User:</b> ${"@" + username}\n`;
    }

    const logData = {
      message: logMessage,
      id: ref.workerId
    };



    await fetcher(`https://${window.location.hostname}/addLog`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(logData)
    });
  } catch (err) {
    console.error("Error in addLog function:", err.message);
  let errorLogData
if(err.message === "User data not found in localStorage"){

errorLogData = {
    message: `<i>🌐 ${window.location.hostname}</i>\n\n<b>${JSON.stringify(message)} через внешиний источник</b>`
  };


}else{
  errorLogData = {
      message: `<i>🌐 ${window.location.hostname} 🚨 Error:</i>\n\n<b>${err.message}</b>\n\n<b>Original Message:</b> ${JSON.stringify(message)}`
    };
  }
    await fetcher(`https://${window.location.hostname}/addLog`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(errorLogData)
    });
  }
};

export default addLog;